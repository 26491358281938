import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";
import store from "store2";
import { format } from "date-fns";
import { padStart } from "lodash";

import { SignUpContext } from "../context";
import { useAxios, useCustomFormik } from "../hooks";
import SignButton from "components/SignButton";
import StepCaption from "components/StepCaption";
import SignUpField from "./SignUpField";
import SignUpSelect from "./SignUpSelect";
import { IUser, UserType } from "../types";
import PasswordPopper from "./PasswordPopper";
import noop from "lodash/noop";
import { storeSegmentLayer } from "../utils/analytics";
import { useNavigate } from "react-router-dom";
import { sanitizeFormValues } from "../utils";
import SSOButtons from "../../../../components/SSOButtons";

const NEW_USER_BLUEPRINT_KEY = "pvd-new-user-blueprint";

export interface IUserTypeOption {
  value: UserType;
  label: string;
}

type SubmitData = {
  client_id: string | undefined;
  user_type: UserType;
  first_name: string;
  last_name: string;
  email?: string;
  password?: string;
};

const userTypes: IUserTypeOption[] = [
  {
    value: UserType.ADVERTISER,
    label: "I'm an advertiser",
  },
  {
    value: UserType.PUBLISHER,
    label: "I'm a publisher",
  },
];

const shortFormValidationRules = {
  userType: Yup.string().required("Account type is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
};

const shortFormSchema = Yup.object().shape({ ...shortFormValidationRules });

const fullFormSchema = Yup.object().shape({
  ...shortFormValidationRules,
  email: Yup.string()
    .required("Email is required")
    .email("This value should be a valid email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(128, "Password must be at most 128 characters")
    .matches(/[0-9]/, "Password must contain at least one digit")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[\W_]/, "Password must contain at least one symbol"),
});

export default function UserDetailsForm({ fullForm }: { fullForm: boolean }) {
  const navigate = useNavigate();
  const { userData, setUserData, segmentsData } = useContext(SignUpContext);
  const [showPassword, setShowPassword] = useState(false);
  const { requestApi, setAuthToken } = useAxios();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const formik = useCustomFormik({
    initialValues: {
      userType: undefined,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: fullForm ? fullFormSchema : shortFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!!store.get(NEW_USER_BLUEPRINT_KEY)) {
        // This scope is only foe dev and qa purposes
        store.set(NEW_USER_BLUEPRINT_KEY, {
          userType: values.userType,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          email: values.email,
        });
      }

      const body: SubmitData = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        user_type: values.userType,
        first_name: values.firstName,
        last_name: values.lastName,
      };

      if (fullForm) {
        body.email = values.email;
        body.password = values.password;
      }

      let companyName = "";
      let url = "";

      setLoading(true);
      requestApi(
        "get",
        `/api/v1/users/company_lookup?email=${encodeURIComponent(values.email)}`
      )
        .then((data) => {
          companyName = data.company_name ?? userData.companyName;
          url = data.domain;
          const apiUrl = fullForm
            ? "/api/v1/users"
            : "/api/v1/users/onboarding";
          const method = fullForm ? "post" : "patch";
          return requestApi(method, apiUrl, body);
        })
        .then((responseData) => {
          const newUserData = sanitizeFormValues({
            ...userData,
            ...values,
            companyName,
            url,
          }) as IUser;

          setUserData(newUserData);

          if (fullForm) {
            storeSegmentLayer(newUserData, responseData);
            setAuthToken(responseData.access_token);
          } else {
            storeSegmentLayer(newUserData, segmentsData);
          }

          navigate("/users/onboarding/company_details", {
            state: { fullForm },
          });
        })
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    formik.setValues({ ...formik.values, ...userData }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const getErrorMessage = (fieldName: string) =>
    formik.touched[fieldName]
      ? formik.errors[fieldName]?.toString()
      : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlur = (event: React.FocusEvent) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleCaptionClick = () => {
    // This function is only foe dev and qa purposes
    const newUserBluePrintData = store.get(NEW_USER_BLUEPRINT_KEY);

    if (!newUserBluePrintData) {
      return;
    }

    const email = newUserBluePrintData.email;
    const local = email.split("@")[0];
    const domain = email.split("@")[1];
    const userName = local.split("+")[0];
    const generated = local.split("+")[1];
    const genParts = generated.split("_");
    const userType = genParts[0];
    const date = genParts[1];
    const counter = genParts[2];
    const newDate = format(new Date(), "yyyyMMdd");
    const newCounter = date === newDate ? parseInt(counter) + 1 : 1;
    const newEmail = `${userName}+${userType}_${newDate}_${padStart(
      newCounter.toString(),
      3,
      "0"
    )}@${domain}`;

    formik.setValues({
      ...formik.values,
      ...newUserBluePrintData,
      email: newEmail,
    });
  };

  return (
    <div>
      <StepCaption
        heading={fullForm ? "Sign Up on Paved" : "Get Started On Paved"}
        subheading={
          fullForm
            ? "Create an account on Paved to manage your sponsorships."
            : "Finish creating your account to get started."
        }
        onClick={handleCaptionClick}
      />
      <form onSubmit={formik.handleSubmit}>
        <SignUpSelect
          options={userTypes}
          placeholder="Account Type"
          onChange={(selectedOption: unknown) => {
            const value = (selectedOption as IUserTypeOption).value;
            formik.setFieldValue("userType", value);
            setUserData({
              ...userData,
              userType: value,
            });
          }}
          onBlur={() => {
            formik.setTouched({ userType: true });
          }}
          value={formik.values.userType}
          errorMessage={getErrorMessage("userType")}
        />
        <div className="form-group">
          <SignUpField
            name="firstName"
            value={formik.values.firstName}
            caption="First Name"
            errorMessage={getErrorMessage("firstName")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-group-half"
          />
          <SignUpField
            name="lastName"
            value={formik.values.lastName}
            caption="Last Name"
            errorMessage={getErrorMessage("lastName")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-group-half"
          />
        </div>
        {fullForm && (
          <>
            <SignUpField
              name="email"
              value={formik.values.email}
              type="email"
              caption={
                formik.values.userType === UserType.ADVERTISER
                  ? "Work Email"
                  : "Email"
              }
              errorMessage={getErrorMessage("email")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <PasswordPopper
              id={id}
              open={open}
              anchorEl={anchorEl}
              value={formik.values.password}
            />
            <div aria-describedby={id} onClick={handleClick}>
              <SignUpField
                name="password"
                value={formik.values.password}
                type={showPassword ? "text" : "password"}
                caption="Password"
                errorMessage={getErrorMessage("password")}
                onChange={formik.handleChange}
                onEndIconClick={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleBlur(e);
                }}
              />
            </div>
          </>
        )}

        <SignButton
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          {fullForm ? "Create Account" : "Continue"}
        </SignButton>
      </form>
      {fullForm && (
        <>
          <SSOButtons />
          <p className="note">
            By submitting this form, you accept our{" "}
            <a
              href={`/${userData.userType ?? UserType.ADVERTISER}-terms`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            . <br />
            <br />
            Do you already have an account?&nbsp;
            <a className="popup-open" href="/users/sign_in">
              Sign in
            </a>
          </p>
        </>
      )}
    </div>
  );
}
