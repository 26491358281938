import { EventInput } from "@fullcalendar/core";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import axios from "axios";
import useComponentDidMount from "hooks/useComponentDidMount";
import { noop } from "lodash";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../components/SiteContext";
import CalendarComponent from "./calendar/CalendarComponent";
import SponsorshipSchedule from "./scheduleForm/ScheduleForm";
import { LayoutContext } from "context";

const SchedulePage = () => {
  const { siteSlug } = useParams();
  const { siteData: site } = useContext(SiteContext);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const { setIsDataLoading } = useContext(LayoutContext);

  const navigate = useNavigate();

  const fetchEvents = async (start?: string, end?: string) => {
    if (!siteSlug) return;
    try {
      const params: Record<string, string> = {};
      if (start && end) {
        params.start = start;
        params.end = end;
      }

      const response = await axios.get(
        `/api/v1/publisher/sites/${siteSlug}/schedule`,
        { params }
      );
      setEvents(response.data);
    } catch (error) {
      noop();
    }
  };

  useComponentDidMount(() => {
    setIsDataLoading(true);
    fetchEvents().then(() => {
      setIsDataLoading(false);
    });
  });

  const handleDatesSet = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
    fetchEvents(start, end);
  };

  const reloadEvents = () => {
    if (startDate && endDate) {
      fetchEvents(startDate, endDate);
    } else {
      fetchEvents();
    }
  };

  if (!siteSlug) return null;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SponsorshipSchedule
            sponsoredEmailEnabled={site.email_ads}
            dedicatedEmailEnabled={site.dedicated_ads}
            initialSponsoredDate={site.sponsored_email_next_date}
            sponsoredDateAnchor={site.sponsored_email_anchor_date}
            dedicatedDateAnchor={site.dedicated_email_schedule_anchor_date}
            initialDedicatedDate={site.dedicated_email_next_date}
            initialSponsoredSchedule={site.sponsored_email_schedule}
            initialDedicatedSchedule={site.dedicated_email_schedule}
            onScheduleUpdated={reloadEvents}
          />

          <Card sx={{ marginBottom: 2 }}>
            <CardContent>
              <CalendarComponent
                siteSlug={siteSlug}
                events={events}
                onDatesSet={handleDatesSet}
                reloadEvents={reloadEvents}
              />
            </CardContent>
          </Card>

          {!!site.status && site.status !== "Live" && (
            <Box display="flex" justifyContent="flex-end" marginTop={3}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  navigate(`/publisher/sites/${siteSlug}/edit/review`);
                }}
              >
                Next
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SchedulePage;
