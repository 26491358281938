import { Box, Typography, Chip as MuiChip, SxProps } from "@mui/material";
import React from "react";

const DiscountBadge = ({
  maxDiscount,
  sx,
}: {
  maxDiscount: number | null;
  sx?: SxProps;
}) => (
  <MuiChip
    label={
      <Box>
        <Typography
          variant="body2"
          component="span"
          display="block"
          fontSize="12px"
          fontWeight={500}
          lineHeight={1}
        >
          Save
        </Typography>
        <Typography
          variant="body2"
          component="span"
          display="block"
          lineHeight={1}
          fontWeight={500}
        >
          {`${maxDiscount}%`}
        </Typography>
      </Box>
    }
    size="small"
    sx={{
      ".MuiChip-label": { px: "15px" },
      bgcolor: "success.main",
      color: "#fff",
      height: "38px",
      fontWeight: 500,
      ml: "auto",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: "32px",
      borderBottomLeftRadius: "32px",
      position: "absolute",
      right: 0,
      textAlign: "center",
      lineHeight: 1,
      ...sx,
    }}
  />
);

export default DiscountBadge;
