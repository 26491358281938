import { useContext, useState } from "react";
import { useChat } from "../context/ChatContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "context";
import { UserType } from "pages/users/sign-up/types";

const useChatHeaderMenu = () => {
  const {
    userData: { userType },
  } = useContext(UserContext);
  const {
    state: { messages },
    markAsUnread,
  } = useChat();
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsUnread = () => {
    navigate({ pathname: `/sponsorships/`, search: location.search });
    if (!campaignId) {
      return;
    }
    markAsUnread(campaignId);
    handleMenuClose();
  };

  const handleViewSponsorship = () => {
    if (!campaignId || !messages?.id) {
      return;
    }
    const userPath =
      userType === UserType.ADVERTISER ? "advertiser" : "publisher";
    window.open(`/${userPath}/campaigns/${messages?.id}`, "_blank");
    handleMenuClose();
  };

  const handleViewDetails = () => {
    navigate(`/sponsorships/${campaignId}/details`);
    handleMenuClose();
  };

  return {
    handleViewDetails,
    messages,
    anchorEl,
    open,
    handleMenuOpen,
    handleMenuClose,
    handleMarkAsUnread,
    handleViewSponsorship,
  };
};

export default useChatHeaderMenu;
