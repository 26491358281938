import { useState, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Campaign } from "../types/types";

const useCampaignList = () => {
  const { campaignId } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCampaignClick = (campaign: Campaign) => {
    if (campaignId !== campaign.uuid) {
      navigate({
        pathname: `/sponsorships/${campaign.uuid}`,
        search: location.search,
      });
    }
  };

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const actionStatusFilter =
    queryParams.get("filters[action_status]") || undefined;

  const statusFilter = queryParams.getAll("filters[status][]") || undefined;

  const sponsorshipTypeFilter =
    queryParams.getAll("filters[sponsorship_type][]") || undefined;

  const searchQuery = queryParams.get("query") || undefined;

  const currentPage = parseInt(queryParams.get("page") || "1", 10);

  const updateQueryParams = (
    updates: Record<string, string | string[] | undefined>
  ) => {
    const params = new URLSearchParams(location.search);

    Object.entries(updates).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        params.delete(key);
        value.forEach((v) => params.append(key, v));
      } else if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const updatePagination = (page: number) => {
    const params = new URLSearchParams(location.search);
    params.set("page", page.toString());
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return {
    anchorEl,
    setAnchorEl,
    handleClosePopover,
    handleCampaignClick,
    statusFilter,
    actionStatusFilter,
    sponsorshipTypeFilter,
    searchQuery,
    currentPage,
    updateQueryParams,
    updatePagination,
  };
};

export default useCampaignList;
