import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../MUIWrappers";
import { Box, IconButton, Stack } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

export type Action = {
  caption: string;
  onClick?: () => void;
  type?: "submit" | "button";
  form?: string;
  severity?: "success" | "info" | "error";
};

export type ConfirmationDialogProps = {
  open: boolean;
  title?: string;
  onClose: () => void;
  actions?: Action[];
  children: React.ReactNode;
  dividers?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
};

export const Actions: React.FC<{ actions: Action[] }> = ({ actions }) => (
  <>
    {actions.map((action) => (
      <Button
        key={action.caption}
        onClick={action.onClick}
        type={action.type || "button"}
        variant={action.type === "submit" ? "contained" : "outlined"}
        fullWidth
        color={action.severity || "primary"}
        form={action.form}
      >
        {action.caption}
      </Button>
    ))}
  </>
);

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  onClose,
  actions = [],
  children,
  dividers = false,
  maxWidth = "xs",
  fullWidth = true,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    {title ? (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 3,
          py: 2,
        }}
      >
        <Box>{title}</Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    ) : (
      <Stack direction="row" justifyContent="flex-end" mt={1} mr={1}>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    )}
    <DialogContent sx={{ p: { xs: 0, sm: 0 } }} dividers={dividers}>
      {children}
    </DialogContent>
    {actions.length > 0 && (
      <DialogActions
        disableSpacing
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1, sm: 2.5 },
          padding: 2,
        }}
      >
        <Actions actions={actions} />
      </DialogActions>
    )}
  </Dialog>
);
