import { isSameDay } from "date-fns";
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useCampaigns } from "../hooks/useCampaigns";
import { useMessages } from "../hooks/useMessages";
import { useTrackEvent } from "../hooks/useTrackEvent";
import { ChatContextProps, GroupedItem, GroupedMessages } from "../types/types";
import { initialState, reducer } from "./reducer";

const POLLING_FREQUENCY = 30000; // 30 seconds
const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatProviderProps {
  children: React.ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { trackEvent, userType, companyName } = useTrackEvent();
  const [state, dispatch] = useReducer(reducer, initialState);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const { fetchCampaigns, markAsRead, markAsUnread } = useCampaigns(
    dispatch,
    trackEvent,
    userType,
    state.filters
  );

  const { fetchMessages, sendMessage } = useMessages(
    dispatch,
    campaignId,
    trackEvent,
    userType,
    companyName,
    messagesContainerRef
  );

  // Determine the selected campaign based on the current campaignId parameter
  const selectedCampaign = useMemo(() => {
    if (campaignId && state.campaigns.length > 0) {
      return state.campaigns.find((c) => c.uuid === campaignId) || null;
    }
    return null;
    //only update when campaignId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  /**
   * Fetch campaigns data initially and set up a polling mechanism
   * to refresh the campaigns list every POLLING_FREQUENCY milliseconds.
   */
  useEffect(() => {
    let isPolling = false;
    const pollCampaigns = async () => {
      if (isPolling) return;
      isPolling = true;
      try {
        await fetchCampaigns();
      } finally {
        isPolling = false;
      }
    };
    fetchCampaigns(true);
    const interval = setInterval(pollCampaigns, POLLING_FREQUENCY);
    return () => clearInterval(interval);
  }, [fetchCampaigns]);

  /**
   * Handle actions when campaignId changes:
   * 1. Mark the campaign as read if it has unread count.
   * 2. Fetch messages for the selected campaign.
   * 3. Track the event of viewing campaign chat.
   * 4. Set up polling for messages.
   */
  useEffect(() => {
    if (campaignId) {
      // Handle actions when campaignId changes
      if (selectedCampaign && selectedCampaign.unread_count > 0) {
        markAsRead(selectedCampaign.uuid);
      }
      fetchMessages(true, false, () => {
        messagesContainerRef.current?.scrollIntoView();
      });
      trackEvent(`${userType}_viewed_campaign_chat`, {
        campaignId: campaignId,
      });

      // Set up polling for messages
      let isPollingMessages = false;
      const pollMessages = async () => {
        if (isPollingMessages) return;
        isPollingMessages = true;
        try {
          await fetchMessages(false, true);
        } finally {
          isPollingMessages = false;
        }
      };

      const interval = setInterval(pollMessages, POLLING_FREQUENCY);

      // Cleanup interval on unmount or when campaignId changes
      return () => clearInterval(interval);
    }
    //disabled to prevent unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, fetchMessages]);

  /**
   * Group messages and statuses by the day they were created.
   * This is useful for rendering messages in a chronological, grouped format.
   */
  const groupedMessages: GroupedMessages[] = useMemo(() => {
    if (!state.messages?.messages || !state.messages?.statuses) return [];

    const combinedItems: GroupedItem[] = [
      ...state.messages.messages.map(
        (message): GroupedItem => ({
          type: "message",
          content: message,
          created_at: message.created_at,
        })
      ),
      ...state.messages.statuses.map(
        (status): GroupedItem => ({
          type: "status",
          content: status,
          created_at: status.time,
        })
      ),
    ];

    combinedItems.sort((a, b) => a.created_at - b.created_at);

    const groups: GroupedMessages[] = [];
    let lastDate: Date | null = null;

    combinedItems.forEach((item) => {
      const itemDate = new Date(item.created_at * 1000);
      if (!lastDate || !isSameDay(itemDate, lastDate)) {
        groups.push({ date: itemDate, items: [item] });
        lastDate = itemDate;
      } else {
        groups[groups.length - 1].items.push(item);
      }
    });

    return groups
      .slice() // Create a shallow copy to avoid mutating original array
      .reverse(); // Reverse the groupedMessages for column-reverse;
  }, [state.messages]);

  /**
   * Memoize the context value to provide performance benefits
   * and prevent unnecessary re-renders of components consuming this context.
   */
  const contextValue = useMemo<ChatContextProps>(
    () => ({
      state,
      fetchMessages,
      sendMessage,
      markAsRead,
      markAsUnread,
      dispatch,
      messagesContainerRef,
      groupedMessages,
    }),
    [
      state,
      fetchMessages,
      sendMessage,
      markAsRead,
      markAsUnread,
      dispatch,
      messagesContainerRef,
      groupedMessages,
    ]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export const useChat = (): ChatContextProps => {
  const context = React.useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
