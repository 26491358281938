import {
  Card,
  Box,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CustomHit } from "./types";
import axios from "axios";
import { formatWithCommas, isInFutureOrToday } from "../utils";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Logo from "./Logo";
import Icon, { ICONS_LIST } from "../Icon";
import { transformKeyToSvgName } from "../utils";
import IconChip from "./IconChip";
import Badge from "./Badge";
import DiscountBadge from "./DiscountBadge";

export const POPULAR_BADGE_BG_COLOR = "#F0F5FE";
export const IN_DEMAND_BADGE_BG_COLOR = "#FCEFF0";

const ItemCard = ({
  hit,
  favorites,
}: {
  hit: CustomHit;
  favorites: number[];
}) => {
  const isTabletOrSmaller = useMediaQuery("(max-width: 1023px)");
  const { slug, top_categories, category_names } = hit;
  const [isFavorite, setIsFavorite] = useState(
    favorites.includes(parseInt(hit.objectID)),
  );

  useEffect(() => {
    setIsFavorite(favorites.includes(parseInt(hit.objectID)));
  }, [favorites]);

  const toggleFavorite = (e: any) => {
    e.preventDefault(); // Prevent the click from navigating to the site
    e.stopPropagation(); // Prevent the click from bubbling up to the parent card

    setIsFavorite(!isFavorite);

    axios({
      method: isFavorite ? "delete" : "post",
      url: `/api/v1/sites/${slug}/favorites`,
    });
  };

  const shouldDisplaySearchBadge = useMemo(
    () =>
      !!hit.search_badge_text && isInFutureOrToday(hit.search_badge_end_date),
    [hit.search_badge_text, hit.search_badge_end_date],
  );

  const rank = useMemo(
    () =>
      top_categories.find(
        (category) => category.category_name === category_names?.[0],
      )?.visit_rank,
    [top_categories, category_names],
  );

  return (
    <Card className="algolia-search__autocomplete-card">
      <Box display="flex" alignItems="flex-start">
        <Logo url={hit.logo_url} isVerified={hit["is_verified?"]} />
        <CardContent
          style={{ minWidth: 0, width: "100%", padding: "0px 0px 0 10px" }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Typography
              variant="subtitle1"
              fontSize={20}
              fontWeight="500"
              color="#0E1951"
              className="algolia-search__autocomplete-title"
            >
              {hit.name}
            </Typography>

            <Box display="flex" ml="auto" alignItems="center">
              {!!hit.max_discount && (
                <DiscountBadge
                  maxDiscount={hit.max_discount}
                  sx={{
                    position: "relative",
                    right: "-16px",
                  }}
                />
              )}
            </Box>
          </Box>

          <Box
            display={isTabletOrSmaller ? "none" : "flex"}
            gap={1}
            alignItems="center"
            position="relative"
          >
            {category_names && category_names.length > 0 && (
              <IconChip
                icon={
                  <Icon
                    name={
                      transformKeyToSvgName(
                        category_names[0],
                      ) as keyof typeof ICONS_LIST
                    }
                  />
                }
                label={category_names}
              />
            )}
            {!!hit.list_size && (
              <>
                <IconChip
                  icon={<Icon name="subscribers" />}
                  label={formatWithCommas(hit.list_size)}
                />
              </>
            )}

            {hit["in_demand?"] && (
              <Badge
                label="In Demand"
                bgColor={IN_DEMAND_BADGE_BG_COLOR}
                textColor="error.main"
                sx={{ height: "24px" }}
              />
            )}

            {shouldDisplaySearchBadge && (
              <Badge
                label={hit.search_badge_text as string}
                bgColor={`${hit.search_badge_background_color}`}
                textColor={`${hit.search_badge_text_color}`}
                sx={{ height: "24px" }}
              />
            )}

            {/* Do not show the badge if it's the 3d existing badge for the item*/}
            {!!rank && !(hit["in_demand?"] && shouldDisplaySearchBadge) && (
              <Badge
                label="Popular"
                bgColor={POPULAR_BADGE_BG_COLOR}
                textColor="primary.dark"
                sx={{ height: "24px" }}
              />
            )}
            <FavoriteBorderIcon
              onClick={toggleFavorite}
              className="algolia-search__favorite-icon"
              sx={{
                ml: "auto",
                color: isFavorite ? "primary.dark" : "#A9ADC9",
                opacity: isFavorite ? 1 : 0.4,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ItemCard;
