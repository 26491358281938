import { Box, CircularProgress, Typography } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import React from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useChat } from "../context/ChatContext";
import useCampaignList from "../hooks/useCampaignList";
import { ChatActions } from "../types/chatActions";
import { Campaign } from "../types/types";
import CampaignItem from "./CampaignItem";
import QuickFilters from "./QuickFilters";
import SearchBar from "./SearchBar";
import SkeletonLoader from "./SkeletonLoader";

const CampaignList: React.FC = () => {
  const { campaignId } = useParams();
  const { handleCampaignClick } = useCampaignList();
  const {
    state: { loading, campaigns, filters, total_count },
    dispatch,
  } = useChat();

  const loadMoreCampaigns = () => {
    if (filters.per_page < total_count) {
      dispatch({
        type: ChatActions.SET_FILTERS,
        payload: { per_page: filters.per_page + 50 },
      });
    }
  };

  return (
    <Box
      maxWidth={{
        xs: "100%",
        md: campaignId ? "25%" : "100%",
        lg: campaignId ? "350px" : "100%",
      }}
      minWidth={{ lg: "350px" }}
      sx={{
        overflowY: "hidden",
        backgroundColor: common.white,
        flexGrow: 1,
        borderRadius: "12px",
        border: `1px solid ${grey[300]}`,
      }}
    >
      <SearchBar />
      <QuickFilters />
      <Box
        sx={{
          height: "calc(100% - 105px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        id="scrollableDiv"
      >
        <InfiniteScroll
          dataLength={campaigns.length}
          next={loadMoreCampaigns}
          hasMore={filters.per_page < total_count}
          loader={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress size={24} />
            </Box>
          }
          endMessage={
            !loading &&
            campaigns.length === 0 && (
              <Box p={2} textAlign="center">
                <Typography variant="body1" color="text.secondary">
                  No campaigns found. Try adjusting your filters.
                </Typography>
              </Box>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {loading && filters.per_page === 50 && <SkeletonLoader count={8} />}

          {!loading &&
            campaigns.length > 0 &&
            campaigns.map((campaign: Campaign) => (
              <CampaignItem
                key={campaign.uuid}
                campaign={campaign}
                selected={campaignId === campaign.uuid}
                onClick={() => handleCampaignClick(campaign)}
              />
            ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default CampaignList;
