import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Badge,
  Box,
  Button,
  IconButton,
  InputBase,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import { useChat } from "../context/ChatContext";
import { ChatActions } from "../types/chatActions";
import CampaignFilters from "./CampaignFilters";
import { useParams } from "react-router-dom";

const SearchBar: React.FC = () => {
  const theme = useTheme();
  const {
    dispatch,
    state: { filters },
  } = useChat();
  const { campaignId } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement as HTMLButtonElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: ChatActions.SET_FILTERS,
        payload: {
          query: event.target.value,
          per_page: 50,
        },
      });
    },
    500
  );

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    dispatch({
      type: ChatActions.SET_FILTERS,
      payload: {
        query: "",
        per_page: 50,
      },
    });
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "campaign-filters-popover" : undefined;
  const filtersCount = useMemo(
    () =>
      (filters.sponsorship_type?.length || 0) + (filters.status?.length || 0),
    [filters.sponsorship_type, filters.status]
  );

  return (
    <Box px={1} pt={1} display="flex" alignItems="center" gap={1}>
      <Badge
        overlap="circular"
        invisible={!filtersCount}
        badgeContent={`${filtersCount}`}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        color="primary"
        sx={{
          "& .MuiBadge-badge": {
            border: "1px solid #fff",
          },
        }}
        onClick={handleOpenPopover}
      >
        <Button
          disableElevation
          variant="contained"
          size="large"
          color="primary"
          sx={{ minWidth: "40px", height: "40px", p: 0, borderRadius: "8px" }}
        >
          <TuneIcon sx={{ fontSize: "20px" }} />
        </Button>
      </Badge>
      <Box
        display="flex"
        alignItems="center"
        border={`1px solid ${theme.palette.grey[300]}`}
        height="40px"
        padding="0 8px"
        borderRadius="8px"
        width={isMobile || campaignId ? "100%" : "50%"}
        position="relative"
      >
        <SearchIcon
          sx={{ color: "#9AA0A6", fontSize: "18px", marginRight: "8px" }}
        />
        <InputBase
          placeholder="Search sponsorship..."
          onChange={handleChange}
          defaultValue={filters.query}
          inputRef={inputRef}
          sx={{
            flexGrow: 1,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#202124",
            "&::placeholder": {
              color: "#9AA0A6",
            },
          }}
        />
        {filters.query && (
          <IconButton
            size="small"
            onClick={handleClear}
            aria-label="clear search"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        elevation={1}
        sx={{ zIndex: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <CampaignFilters onClose={handleClosePopover} />
      </Popover>
    </Box>
  );
};

export default SearchBar;
