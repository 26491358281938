import Close from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import { UserContext } from "context";
import { differenceInDays, fromUnixTime } from "date-fns";
import { noop } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Icon, Button } from "shared-components";

interface OptOutBannerProps {
  showOptOutBanner: boolean;
  handleClose: () => void;
}

const paperProps = {
  sx: {
    borderRadius: "16px",
    maxWidth: "420px",
    p: 4,
  },
};

const contentSx = { textAlign: "center", p: 0 };
const actionsSx = {
  p: 0,
  mt: 4,
  justifyContent: "space-between",
  width: "100%",
  gap: "20px",
  "@media (max-width: 480px)": {
    flexWrap: "wrap",
  },
};
const closeIconSx = {
  position: "absolute",
  right: 8,
  top: 8,
  color: (theme: any) => theme.palette.grey[500],
};

const StarIcon = () => (
  <Icon name="star" width={112} height={112} fill="#FFAD33" weight={300} />
);

const FeedbackIcon = () => (
  <Icon name="sad" width={112} height={112} fill="#6A9EF8" weight={300} />
);

const OptOutBanner: React.FC<OptOutBannerProps> = ({
  showOptOutBanner,
  handleClose,
}) => {
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [isSwitchedOn, setIsSwitchedOn] = useState(true);
  const { userData } = useContext(UserContext);

  const modalName = "inbox_beta_feedback";

  const typeformURL =
    `https://d5pi0hqo496.typeform.com/to/yXRPNTsA#` +
    `email=${encodeURIComponent(userData.email ?? "")}` +
    `&name=${encodeURIComponent(userData.firstName ?? "")}` +
    `&user_id=${encodeURIComponent(userData.ro_id ?? "")}` +
    `&company_name=${encodeURIComponent(userData.companyName ?? "")}` +
    `&user_type=${encodeURIComponent(userData.userType ?? "")}`;

  useEffect(() => {
    const checkAndMaybeShowModal = async () => {
      try {
        const response = await axios.get(`/api/v1/modal_views/${modalName}`);
        const lastSeen = response.data.last_seen;

        const lastSeenDate = lastSeen ? fromUnixTime(lastSeen) : undefined;

        if (!lastSeen) {
          try {
            await axios.post("/api/v1/modal_views/", {
              modal_name: modalName,
            });
          } catch (postError) {
            noop();
          }
        }

        const now = new Date();

        const typeformOpened = localStorage.getItem("typeformOpened");

        if (typeformOpened) {
          return;
        }

        if (lastSeenDate && differenceInDays(now, lastSeenDate) >= 5) {
          setWelcomeModalOpen(true);
        }
      } catch (error) {
        noop();
      }
    };

    if (showOptOutBanner) {
      checkAndMaybeShowModal();
    }
  }, [showOptOutBanner, modalName]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsSwitchedOn(checked);
    if (!checked) setFeedbackModalOpen(true);
  };

  const handleSubmitOptOut = async () => {
    try {
      await axios.patch("/api/v1/companies/opt_out_from_feature", {
        feature: "chats",
      });
      setFeedbackModalOpen(false);
      window.location.href = "/publisher/campaigns";
    } catch {
      noop();
    }
  };

  const handleCloseAll = () => {
    setWelcomeModalOpen(false);
    setFeedbackModalOpen(false);
  };

  const handleTypeformClick = () => {
    localStorage.setItem("typeformOpened", "true");
    setWelcomeModalOpen(false);
    setFeedbackModalOpen(false);
  };

  return (
    <>
      {showOptOutBanner && (
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Alert
            severity="info"
            icon={
              <IconButton onClick={handleClose} size="small">
                <Close />
              </IconButton>
            }
            sx={{
              mb: 2,
              alignItems: "center",
              flexGrow: 1,
              height: "48px",
              color: "primary.main",
              borderRadius: "8px",
            }}
            action={
              <Switch checked={isSwitchedOn} onChange={handleSwitchChange} />
            }
          >
            <b>Try our new sponsorship dashboard!</b> Feel free to switch back
            to the classic view anytime in the next 3 months.
          </Alert>
        </Stack>
      )}
      <Dialog
        open={welcomeModalOpen}
        onClose={handleCloseAll}
        fullWidth
        PaperProps={paperProps}
      >
        <DialogContent sx={contentSx}>
          <IconButton
            aria-label="close"
            onClick={handleCloseAll}
            sx={closeIconSx}
          >
            <Close />
          </IconButton>
          <Box mt={4}>
            <StarIcon />
            <Typography
              sx={{ fontWeight: 700, fontSize: "24px" }}
              gutterBottom
              mt={4}
            >
              Welcome Back!
            </Typography>
            <Box mt={2} fontSize={14} color="text.secondary" textAlign="center">
              We see you&apos;re exploring our new dashboard. We&apos;d love
              your feedback to help us enhance it for everyone.
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsSx}>
          <Button
            onClick={() => setWelcomeModalOpen(false)}
            variant="outlined"
            sx={{
              flexBasis: "50%",
              "@media (max-width: 480px)": {
                flexBasis: "100%",
              },
            }}
          >
            Skip for Now
          </Button>
          <Link
            href={typeformURL}
            target="_blank"
            sx={{
              textDecoration: "none",
              flexBasis: "50%",
              display: "flex",
              ml: "0 !important",
              "@media (max-width: 480px)": {
                flexBasis: "100%",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                p: "0 19px 0 18px",
                width: "100%",
              }}
              onClick={handleTypeformClick}
            >
              Share Your Opinion
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
      <Dialog
        open={feedbackModalOpen}
        onClose={() => {
          setFeedbackModalOpen(false);
          handleSubmitOptOut();
        }}
        fullWidth
        PaperProps={paperProps}
      >
        <DialogContent sx={contentSx}>
          <IconButton
            size="small"
            onClick={() => handleSubmitOptOut()}
            sx={closeIconSx}
          >
            <Close />
          </IconButton>
          <Box mt={4}>
            <FeedbackIcon />
            <Typography
              sx={{ fontWeight: 700, fontSize: "24px" }}
              gutterBottom
              mt={4}
            >
              Share Your Feedback
            </Typography>
            <Box mt={2} fontSize={14} color="text.secondary" textAlign="center">
              You&apos;ve just switched back to the old dashboard. Your input is
              essential for perfecting the new experience.
              <br /> What improvements can we make?
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsSx}>
          <Button
            onClick={() => {
              setFeedbackModalOpen(false);
              handleSubmitOptOut();
            }}
            variant="outlined"
            sx={{
              flexBasis: "50%",
              "@media (max-width: 480px)": {
                flexBasis: "100%",
              },
            }}
          >
            No Thanks
          </Button>
          <Link
            href={typeformURL}
            target="_blank"
            sx={{
              textDecoration: "none",
              flexBasis: "50%",
              display: "flex",
              ml: "0 !important",
              "@media (max-width: 480px)": {
                flexBasis: "100%",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                p: "0 19px 0 18px",
                width: "100%",
              }}
              onClick={() => {
                handleTypeformClick();
                handleSubmitOptOut();
              }}
            >
              Share Your Opinion
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OptOutBanner;
