import React from "react";

export default function StepCaption({
  heading,
  subheading,
  onClick,
}: {
  heading: string;
  subheading?: string;
  onClick?: () => void;
}) {
  return (
    <div className="step active" onClick={onClick}>
      <p className="heading">{heading}</p>
      <p className="subheading">{subheading}</p>
    </div>
  );
}
