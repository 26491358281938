import {
  ThumbUpOutlined as ApprovalIcon,
  CheckBoxOutlined as CompletedIcon,
  EmojiObjectsOutlined as CreativeIcon,
  Description as DraftIcon,
  AddCard as PaymentIcon,
  EditNoteOutlined as RequestedIcon,
  EventAvailableOutlined as ScheduledIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useChat } from "../context/ChatContext";
import { ChatActions } from "../types/chatActions";
import { getCampaigns } from "../api/chatsAPI";

type FilterOption = {
  label: string;
  icon?: JSX.Element;
  key: string;
};

const sponsorshipOptions: FilterOption[] = [
  { label: "Sponsored Email", key: "sponsored_email" },
  { label: "Sponsored Post", key: "sponsored_post" },
  { label: "Dedicated Email", key: "dedicated_email" },
];

const statusOptions: FilterOption[] = [
  { label: "Requested", icon: <RequestedIcon />, key: "Requested" },
  { label: "Creative", icon: <CreativeIcon />, key: "Creative" },
  { label: "Payment", icon: <PaymentIcon />, key: "Payment" },
  { label: "Awaiting Draft", icon: <DraftIcon />, key: "Awaiting Draft" },
  { label: "Approval", icon: <ApprovalIcon />, key: "Approval" },
  { label: "Scheduled", icon: <ScheduledIcon />, key: "Scheduled" },
  { label: "Completed", icon: <CompletedIcon />, key: "Sent" }, // Corrected key
];

//
const arraysEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false;
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return sortedA.every((value, index) => value === sortedB[index]);
};

interface CampaignFiltersProps {
  onClose: () => void;
}

const CampaignFilters: React.FC<CampaignFiltersProps> = ({ onClose }) => {
  const {
    state: { filters },
    dispatch,
  } = useChat();

  const [localFilters, setLocalFilters] = useState<{
    sponsorship_type: string[];
    status: string[];
  }>({
    sponsorship_type: filters.sponsorship_type || [],
    status: filters.status || [],
  });

  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialFilters = useRef({
    sponsorship_type: filters.sponsorship_type || [],
    status: filters.status || [],
  });

  useEffect(() => {
    const newFilters = {
      sponsorship_type: filters.sponsorship_type || [],
      status: filters.status || [],
    };
    setLocalFilters(newFilters);
    initialFilters.current = newFilters;
  }, [filters.sponsorship_type, filters.status]);

  const fetchCampaignCount = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getCampaigns({
        status: localFilters.status,
        sponsorship_type: localFilters.sponsorship_type,
        count_only: true,
      });
      setCount(response.total_count);
    } catch (error) {
      setCount(0);
    } finally {
      setIsLoading(false);
    }
  }, [localFilters.status, localFilters.sponsorship_type]);

  useEffect(() => {
    fetchCampaignCount();
  }, [fetchCampaignCount]);

  const handleFilterChange = useCallback(
    (key: keyof typeof localFilters, value: string, checked: boolean) => {
      setLocalFilters((prevFilters) => ({
        ...prevFilters,
        [key]: checked
          ? [...prevFilters[key], value]
          : prevFilters[key].filter((item) => item !== value),
      }));
    },
    []
  );

  const renderFilters = useCallback(
    (options: FilterOption[], key: keyof typeof localFilters) => (
      <Box p={1}>
        <Typography
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={14}
          sx={{ marginBottom: 1 }}
        >
          {key.split("_").join(" ")}:
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {options.map((option) => (
            <Chip
              key={option.key}
              label={option.label}
              icon={option.icon}
              clickable
              color={
                localFilters[key].includes(option.key) ? "primary" : "default"
              }
              onClick={() =>
                handleFilterChange(
                  key,
                  option.key,
                  !localFilters[key].includes(option.key)
                )
              }
              sx={{
                fontWeight: 500,
              }}
            />
          ))}
        </Box>
      </Box>
    ),
    [handleFilterChange, localFilters]
  );

  const handleClearAll = useCallback(() => {
    setLocalFilters({
      sponsorship_type: [],
      status: [],
    });
  }, []);

  const handleSave = useCallback(() => {
    dispatch({
      type: ChatActions.SET_FILTERS,
      payload: {
        sponsorship_type: localFilters.sponsorship_type,
        status: localFilters.status,
        action_status: undefined,
        per_page: 50,
      },
    });
    onClose();
  }, [dispatch, localFilters, onClose]);

  const isChanged = useMemo(() => {
    return (
      !arraysEqual(
        localFilters.sponsorship_type,
        initialFilters.current.sponsorship_type
      ) || !arraysEqual(localFilters.status, initialFilters.current.status)
    );
  }, [localFilters]);

  return (
    <Card
      elevation={0}
      sx={{
        width: "348px",
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ p: 1 }}>
        {renderFilters(sponsorshipOptions, "sponsorship_type")}
        {renderFilters(statusOptions, "status")}
      </CardContent>
      <Divider />
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <Button
          sx={{ borderRadius: "8px" }}
          variant="outlined"
          color="inherit"
          onClick={handleClearAll}
          disabled={
            isLoading ||
            (!localFilters.sponsorship_type.length &&
              !localFilters.status.length)
          }
        >
          Clear All
        </Button>
        <Button
          sx={{ borderRadius: "8px", minWidth: "180px" }}
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!isChanged || isLoading || !count}
          startIcon={
            isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : undefined
          }
        >
          {isLoading
            ? ""
            : `Show ${count} sponsorship${count !== 1 ? "s" : ""}`}
        </Button>
      </CardActions>
    </Card>
  );
};

export default React.memo(CampaignFilters);
