import cls from "classnames";
import axios from "axios";
import { useContext, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  formatWithCommas,
  Icon,
  isInFutureOrToday,
  Logo,
  transformKeyToSvgName,
  ICONS_LIST,
  IconChip,
  Badge,
  DiscountBadge,
  POPULAR_BADGE_BG_COLOR,
  IN_DEMAND_BADGE_BG_COLOR,
} from "shared-components";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { UserContext } from "../../../context";
import { UserType } from "pages/users/sign-up/types";

export type Hit = {
  objectID: string;
  slug: string;
  "is_top_rated?": boolean;
  "is_verified?": boolean;
  logo_url: string;
  name: string;
  category_names: string[] | null;
  description: string;
  short_description: string;
  max_discount: number | null;
  search_badge_background_color: string | null;
  search_badge_end_date: string | null;
  search_badge_text: string | null;
  search_badge_text_color: string | null;
  list_size: number;
  "in_demand?": boolean;
  previous_advertiser_names: string[];
  top_categories: { category_name: string; visit_rank: number }[];
};
export type HitProps = {
  hit: Hit;
  favorites: number[];
};

const ADVERTISER_NAMES_LINE_CLAMP_THRESHOLD = 5;

export default function HitComponent({ hit, favorites }: HitProps) {
  const {
    userData: { userType },
  } = useContext(UserContext);

  const isAdvertiser = userType === UserType.ADVERTISER;

  const {
    objectID,
    slug,
    logo_url,
    name,
    category_names,
    description,
    max_discount,
    search_badge_background_color,
    search_badge_end_date,
    search_badge_text,
    search_badge_text_color,
    list_size,
    short_description,
    previous_advertiser_names,
    top_categories,
  } = hit;

  const [isFavorite, setIsFavorite] = useState(
    favorites.includes(parseInt(objectID))
  );

  const toggleFavorite = (e: any) => {
    e.preventDefault(); // Prevent the click from navigating to the site

    setIsFavorite(!isFavorite);

    axios({
      method: isFavorite ? "delete" : "post",
      url: `/api/v1/sites/${slug}/favorites`,
    });
  };

  const rank = useMemo(
    () =>
      top_categories.find(
        (category) => category.category_name === category_names?.[0]
      )?.visit_rank,
    [top_categories, category_names]
  );

  return (
    <div className="item item-half">
      <div data-id={objectID} className={cls("card")}>
        <a className="link" href={`/sites/${slug}`}>
          <Box display="flex" alignItems="center" gap={2} mb={2}>
            <Logo url={logo_url} isVerified={hit["is_verified?"]} />
            <strong className="company">{name}</strong>
            {!!max_discount && <DiscountBadge maxDiscount={max_discount} />}
          </Box>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            mb={1}
            mt={1}
            position="relative"
          >
            {category_names && category_names.length > 0 && (
              <IconChip
                icon={
                  <Icon
                    name={
                      transformKeyToSvgName(
                        category_names[0]
                      ) as keyof typeof ICONS_LIST
                    }
                  />
                }
                labelClassName="chip-label"
                label={`${category_names[0]}`}
              />
            )}
            {!!list_size && (
              <>
                <IconChip
                  icon={<Icon name="subscribers" />}
                  label={formatWithCommas(list_size)}
                  labelClassName="chip-label"
                />
              </>
            )}
            {isAdvertiser && (
              <FavoriteBorderIcon
                onClick={toggleFavorite}
                sx={{
                  ml: "auto",
                  color: isFavorite ? "primary.dark" : "#627283",
                  opacity: isFavorite ? 1 : 0.4,
                  mr: "-5px",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </Box>
          <Box
            component="p"
            mb={0}
            className={cls("description", {
              "line-clamp-1": previous_advertiser_names.length > 0,
              "line-clamp-2": previous_advertiser_names.length === 0,
              hidden:
                previous_advertiser_names.length >
                ADVERTISER_NAMES_LINE_CLAMP_THRESHOLD,
            })}
          >
            {short_description ? short_description : description}
          </Box>
          {previous_advertiser_names.length > 0 && (
            <Box
              component="p"
              className={cls("description", {
                "line-clamp-1":
                  previous_advertiser_names.length <=
                  ADVERTISER_NAMES_LINE_CLAMP_THRESHOLD,
                "line-clamp-2":
                  previous_advertiser_names.length >
                  ADVERTISER_NAMES_LINE_CLAMP_THRESHOLD,
              })}
            >
              Previous Sponsors:{" "}
              <Typography
                component="span"
                sx={{ color: "text.primary" }}
                fontWeight={500}
              >
                {previous_advertiser_names.join(", ")}
              </Typography>
            </Box>
          )}

          <Box display="flex" gap={1} mt={2}>
            {!!rank && (
              <Badge
                label="Popular"
                bgColor={POPULAR_BADGE_BG_COLOR}
                textColor="primary.dark"
              />
            )}
            {hit["in_demand?"] && (
              <Badge
                label="In Demand"
                bgColor={IN_DEMAND_BADGE_BG_COLOR}
                textColor="error.main"
              />
            )}

            {search_badge_text && isInFutureOrToday(search_badge_end_date) && (
              <Badge
                label={search_badge_text}
                bgColor={`${search_badge_background_color}`}
                textColor={`${search_badge_text_color}`}
              />
            )}
          </Box>
        </a>
      </div>
    </div>
  );
}
