import { PreviewOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import { useChat } from "../context/ChatContext";
import useChatHeaderMenu from "../hooks/useChatHeaderMenu";
import { getDeadlineColor } from "../utils/deadlineColor";

const SponsorshipDetails: React.FC = () => {
  const {
    state: { messages },
  } = useChat();
  const { handleViewSponsorship } = useChatHeaderMenu();

  const details = [
    { label: "Status", value: messages?.sponsorship?.status },
    {
      label: "Type",
      value: (
        <>
          {messages?.sponsorship?.type}
          <br />
          {messages?.sponsorship?.name}
        </>
      ),
    },
    {
      label: "Deadline",
      value: messages?.sponsorship?.deadline
        ? format(
            fromUnixTime(messages.sponsorship.deadline),
            "EEEE, MMMM d, yyyy"
          )
        : null,
    },
    {
      label: "Date",
      value: messages?.sponsorship?.run_date
        ? format(new Date(messages.sponsorship.run_date), "EEEE, MMMM d, yyyy")
        : null,
    },
    {
      label: "Price",
      value: messages?.sponsorship?.price
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(parseFloat(messages?.sponsorship.price))
        : null,
    },
    {
      label: "List Size",
      value: messages?.sponsorship?.list_size
        ? messages?.sponsorship.list_size.toLocaleString("en-US")
        : null,
    },
  ];

  return (
    <Box
      maxWidth={{ xs: "100%", sm: "33%", lg: "240px" }}
      minWidth={{ xs: "100%", sm: "33%", lg: "240px" }}
      display="flex"
      flexDirection="column"
    >
      {messages && (
        <>
          <Box p={2} overflow="auto">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              mb={1.5}
              sx={{ fontSize: "1rem" }}
            >
              Sponsorship Details
            </Typography>
            {details.map((item) => {
              if (!item.value) return null;
              return (
                <Box
                  mb={2}
                  key={item.label}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography fontWeight="bold" fontSize="0.875rem">
                    {item.label}:
                  </Typography>
                  {item.label === "Status" ? (
                    <Chip
                      label={item.value || "N/A"}
                      size="small"
                      color="primary"
                      sx={{
                        height: "20px",
                        width: "fit-content",
                        bgcolor: blue[50],
                        color: "primary.main",
                      }}
                    />
                  ) : (
                    <Typography
                      variant="caption"
                      color={
                        item.label === "Deadline"
                          ? getDeadlineColor(messages?.sponsorship?.deadline!)
                          : "text.disabled"
                      }
                    >
                      {item.value || "N/A"}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>

          <Box
            height="56px"
            padding="8px 16px"
            mt="auto"
            borderTop="1px solid #E0E0E0"
          >
            <Button
              variant="outlined"
              fullWidth
              onClick={handleViewSponsorship}
              sx={{
                borderRadius: "8px",
                fontSize: "0.875rem",
                height: "40px",
                boxShadow: "none",
              }}
            >
              <PreviewOutlined sx={{ mr: 1 }} />
              <Typography noWrap variant="button" textTransform="none">
                View Sponsorship
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SponsorshipDetails;
