import { Box, Chip, Divider, LinearProgress } from "@mui/material";
import { UserContext } from "context";
import { format, isToday, isYesterday } from "date-fns";
import React, { useContext, useEffect } from "react";
import { useChat } from "../context/ChatContext";
import { Message } from "../types/types";
import MessageItem from "./MessageItem";

export const MessageList: React.FC = () => {
  const { userData } = useContext(UserContext);

  const {
    groupedMessages,
    state: { messagesLoading },
    messagesContainerRef,
  } = useChat();

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = 0;
    }
  }, [messagesLoading, messagesContainerRef]);

  const messagesList = (
    <Box
      ref={messagesContainerRef}
      sx={{
        p: 2,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column-reverse",
        position: "relative",
        flexGrow: 1,
        height: "100%",
      }}
    >
      {groupedMessages.map((group) => (
        <Box key={format(group.date, "yyyy-MM-dd")}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={2}
          >
            <Chip
              size="small"
              sx={{ bgcolor: "#E6FFED", borderRadius: "5px", p: 0 }}
              label={
                isToday(group.date)
                  ? "Today"
                  : isYesterday(group.date)
                  ? "Yesterday"
                  : format(group.date, " MMMM do, yyyy")
              }
            />
          </Box>

          {group.items.map((item) => (
            <Box key={item.created_at}>
              {item.type === "message" ? (
                <MessageItem
                  message={item.content as Message}
                  isCompanyMessage={
                    (item.content as Message).author.name ===
                    userData.companyName
                  }
                />
              ) : (
                <Box mb={2} display="flex" alignItems="center" width="100%">
                  <Divider sx={{ flexGrow: 1 }} />
                  <Box
                    sx={{
                      bgcolor: "#E6FFED",
                      borderRadius: "5px",
                      p: "4px",
                      mx: 1,
                      maxWidth: "80%",
                      alignItems: "center",
                      fontSize: "13px",
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "text.primary",
                    }}
                  >
                    {(item.content as { action: string }).action}
                  </Box>
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );

  return (
    <>
      {messagesLoading && (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: 0,
            zIndex: 1,
          }}
        >
          <LinearProgress />
        </Box>
      )}
      {messagesLoading ? <Box height="100%" /> : messagesList}
    </>
  );
};
