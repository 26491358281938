import React, { useMemo } from "react";
import { InstantSearch, useRelatedProducts } from "react-instantsearch";
import ConnectedAutoComplete from "./ConnectedAutoComplete";
import { SearchClient } from "algoliasearch/lite";
import { Hit } from "instantsearch.js";
import { CustomHit } from "./types";

type AlogoliaSearchProps = {
  indexName: string;
  searchClient: SearchClient;
  isEmbedded: boolean;
  objectId?: string;
  onFocus: () => void;
  onBlur: () => void;
};

const RelatedItemsAutoComplete = ({
  objectId,
  isEmbedded,
  onFocus,
  onBlur,
}: {
  objectId: string;
  isEmbedded: boolean;
  onFocus: () => void;
  onBlur: () => void;
}) => {
  const { items } = useRelatedProducts({
    objectIDs: [objectId],
  }) as { items: Hit<CustomHit>[] };

  const relatedProducts = useMemo(() => {
    return items.slice(0, 5);
  }, [items]);

  return (
    <ConnectedAutoComplete
      relatedProducts={relatedProducts}
      isEmbedded={isEmbedded}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default function AlogoliaSearch({
  indexName,
  searchClient,
  isEmbedded,
  objectId,
  onFocus,
  onBlur,
}: AlogoliaSearchProps) {
  if (objectId)
    return (
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <RelatedItemsAutoComplete
          objectId={objectId}
          isEmbedded={isEmbedded}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </InstantSearch>
    );

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <ConnectedAutoComplete
        isEmbedded={isEmbedded}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InstantSearch>
  );
}
