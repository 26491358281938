import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";

const agreementListItems = [
  "Keep my calendar updated.",
  'Honor the "Minimum Price" I set.',
  "Respond to sponsorship requests promptly.",
];
const AgreementBox = ({
  agreement,
  toggleAgreement,
}: {
  agreement: boolean;
  toggleAgreement: () => void;
}) => (
  <Box px={2.5} py={1.5} bgcolor={indigo[50]} borderRadius={1}>
    <Typography variant="body2">
      Before starting your offer, please confirm your agreement to the
      following:
    </Typography>
    <FormControlLabel
      sx={{ color: "text.secondary", pl: 1 }}
      control={
        <Checkbox checked={agreement} onChange={toggleAgreement} size="small" />
      }
      label={
        <Typography variant="caption" fontWeight="bold">
          I agree that I will:
        </Typography>
      }
    />
    <FormHelperText sx={{ my: 0, px: "36px" }} component="div">
      <List sx={{ py: 0 }} dense component="div">
        {agreementListItems.map((item, index) => (
          <ListItem sx={{ px: 0 }} key={index}>
            {item}
          </ListItem>
        ))}
      </List>
    </FormHelperText>
  </Box>
);

export default AgreementBox;
