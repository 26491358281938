import { Chip as MuiChip, SxProps } from "@mui/material";
import React from "react";

const Badge = ({
  label,
  bgColor,
  textColor,
  sx,
}: {
  label: string;
  bgColor: string;
  textColor: string;
  sx?: SxProps;
}) => (
  <MuiChip
    label={label}
    size="medium"
    sx={{
      bgcolor: bgColor,
      color: textColor,
      height: "20px",
      fontWeight: 500,
      borderRadius: "20px",
      ...sx,
    }}
  />
);

export default Badge;
