import React, { useState, useContext, useMemo } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useComponentDidMount from "hooks/useComponentDidMount";
import { useSnackbar } from "context";

import PayoutCalculator from "../components/payoutCalculator/PayoutCalculator";
import { LayoutContext } from "context";
import { VALIDATOR_STRING_REQUIRED } from "utils/validators";
import { SiteContext } from "../components/SiteContext";
import FormWrapper from "components/FormWrapper";
import { publishedPriceSchema } from "../sponsoredEmail";

const validationSchema = yup.object().shape({
  ad_description: VALIDATOR_STRING_REQUIRED,
  published_price: publishedPriceSchema,
});

export default function SponsoredPost() {
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { siteSlug } = useParams();
  const { siteData } = useContext(SiteContext);
  const { isDataLoading, setIsDataLoading } = useContext(LayoutContext);
  const [initialValues, setInitialValues] = useState({
    ad_description: "",
    published_price: 0,
  });
  const apiUrl = useMemo(
    () =>
      `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_post`,
    [siteSlug]
  );

  useComponentDidMount(() => {
    setIsDataLoading(true);
    axios({
      method: "get",
      url: apiUrl,
    })
      .then((response) => {
        setIsDataLoading(false);
        setInitialValues({
          ad_description: response.data.ad_description ?? "",
          published_price: response.data.published_price ?? "",
        });
      })
      .catch((error) => {
        setIsDataLoading(false);
      });
  });

  const handleNextButton = () => {
    if (siteData.status === "Live") {
      openSnackbar("Sponsorship updated!");
    } else {
      navigate(nextRoute);
    }
  };

  const nextRoute = useMemo(() => {
    let route = "review";

    if (siteData.dedicated_ads) {
      route = "dedicated_email";
    } else if (siteData.email_ads) {
      route = "sponsored_email";
    }

    return `/publisher/sites/${siteSlug}/edit/${route}`;
  }, [siteSlug, siteData]);

  const handleSubmit = (values: {
    ad_description: string;
    published_price: number;
  }) => {
    setIsDataLoading(true);
    const formdata = new FormData();
    formdata.append("ad_description", values.ad_description);
    formdata.append("published_price", values.published_price.toString());
    axios
      .patch(apiUrl, formdata)
      .then(() => {
        handleNextButton();
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Sponsored Post
      </Typography>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (initialValues === values) {
            return handleNextButton();
          }

          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              <Card>
                <CardHeader
                  title="Post Details"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                      <Typography paddingBottom={3.5}>
                        Let advertisers know what they can expect from a
                        sponsored blog post
                      </Typography>
                    </Grid>
                    <Grid item>
                      <InputLabel htmlFor="ad_description">
                        Ad Specification
                      </InputLabel>
                      <TextField
                        id="ad_description"
                        fullWidth={true}
                        name="ad_description"
                        multiline
                        rows={8}
                        placeholder="Example: We will write all content on behalf of the advertiser. Blog posts will be a minimum of 700 words and can feature up to 3 images."
                        value={values.ad_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.ad_description &&
                          Boolean(errors.ad_description)
                        }
                        helperText={
                          touched.ad_description &&
                          Boolean(errors.ad_description)
                            ? errors.ad_description
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardHeader
                  title="Pricing"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  <PayoutCalculator
                    name="published_price"
                    value={values.published_price}
                    error={errors.published_price}
                    touched={touched.published_price}
                    type="sponsored_post"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </CardContent>
              </Card>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isDataLoading}
                  endIcon={
                    isDataLoading ? (
                      <CircularProgress size={12} sx={{ color: "#fff" }} />
                    ) : null
                  }
                >
                  {siteData.status === "Live" ? "Save Changes" : "Next"}
                </Button>
              </Box>
            </Box>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
}
