import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

const SkeletonLoader: React.FC<{ count: number }> = ({ count }) => {
  const { campaignId } = useParams();
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <ListItem key={index} sx={{ p: 1, borderBottom: "1px solid #F1F1F1" }}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={56} height={56} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Stack direction="row" alignItems="center" gap={1} ml={1}>
                <Skeleton variant="text" width="33%" height={20} />
                <Box
                  sx={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Skeleton variant="text" width={60} height={20} />
                </Box>
              </Stack>
            }
            secondary={
              <Stack pl={1} spacing={0.5}>
                {!!campaignId ? (
                  <>
                    <Skeleton variant="text" width="80%" height={16} />
                    <Skeleton variant="text" width="50%" height={16} />
                  </>
                ) : (
                  <Stack direction={"row"} spacing={2}>
                    <Skeleton variant="text" width="25%" height={16} />
                    <Skeleton variant="text" width="25%" height={16} />
                    <Skeleton variant="text" width="25%" height={16} />
                    <Skeleton variant="text" width="25%" height={16} />
                  </Stack>
                )}
              </Stack>
            }
          />
        </ListItem>
      ))}
    </>
  );
};

export default SkeletonLoader;
