import React from "react";
import { FormHelperText } from "@mui/material";
import PayoutCalculator from "./payoutCalculator/PayoutCalculator";
import { SMART_PUBLISHED_PRICE_MIN } from "../hooks/useSmartPricing";

interface SponsorshipPricingProps {
  values: {
    smart_pricing_enabled: boolean;
    published_price: number;
    smart_pricing_lowest_price: number;
  };
  errors: {
    published_price?: string;
    smart_pricing_lowest_price?: string;
  };
  prefix?: string;
  touched: {
    published_price?: boolean;
    smart_pricing_lowest_price?: boolean;
  };
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  smartPricingDisabled: boolean;
  type: "dedicated_email" | "sponsored_email" | "sponsored_post";
  onPriceBlur?: (e: React.FocusEvent<any>) => void;
}

const SponsorshipPricing: React.FC<SponsorshipPricingProps> = ({
  values,
  errors,
  prefix,
  touched,
  onChange,
  onBlur,
  smartPricingDisabled,
  type,
  onPriceBlur,
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    onPriceBlur?.(e);
  };

  return (
    <>
      <PayoutCalculator
        name={prefix ? `${prefix}.published_price` : "published_price"}
        label={"Regular Sponsorship Price"}
        value={values.published_price}
        error={errors.published_price}
        touched={touched.published_price}
        type={type}
        onChange={onChange}
        onBlur={handleBlur}
      />
      <FormHelperText>
        Minimal amount is {SMART_PUBLISHED_PRICE_MIN}.00 USD
      </FormHelperText>
    </>
  );
};

export default SponsorshipPricing;
