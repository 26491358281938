import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormHelperText,
  Link,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import AgreementBox from "./AgreementBox";

interface SmartPricingProps {
  name: string;
  values: {
    smart_pricing_enabled: boolean;
    published_price: number;
    smart_pricing_lowest_price: number;
  };
  smartPricingDisabled: boolean;
  agreement: boolean;
  smartPricingToggle: (checked?: boolean) => void;
  toggleAgreement: () => void;
  buttonRef?: React.MutableRefObject<HTMLInputElement | null>;
}

const helperTextListItems = [
  "Automatically offer last-minute discounts to attract advertisers, keeping your price within your set range.",
  "Automatically lowers the price for unsold sponsorships within the next 14 days.",
  "You can change this offer anytime.",
];

const SmartPricingSwitch = ({
  name,
  values,
  smartPricingDisabled,
  smartPricingToggle,
  buttonRef,
  agreement,
  toggleAgreement,
}: SmartPricingProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const pointerType = (event.nativeEvent as PointerEvent).pointerType;
    if (pointerType === "mouse") {
      window.open(
        "https://www.paved.com/help/articles/8124581-turn-on-smart-pricing",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (pointerType === "touch") {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const tooltipTitle = (
    <>
      <Typography sx={{ fontSize: "0.75rem", fontWeight: 500 }}>
        The Smart Pricing feature lets you set a dynamic price range for your
        sponsorships instead of a fixed price.
      </Typography>
      <Link
        href="https://www.paved.com/help/articles/8124581-turn-on-smart-pricing"
        target="_blank"
        rel="noreferrer"
        sx={{
          color: grey[50],
          textDecoration: "underline",
          "&:hover": {
            textDecorationColor: grey[500],
            color: grey[500],
          },
          fontSize: "0.75rem",
          fontWeight: 500,
        }}
      >
        Learn how Smart Pricing works
      </Link>
    </>
  );

  return (
    <>
      <Divider />
      <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
        <Tooltip
          title={tooltipTitle}
          open={tooltipOpen}
          onOpen={() => setTooltipOpen(true)}
          onClose={() => setTooltipOpen(false)}
          onClick={handleTooltipClick}
          leaveTouchDelay={5000}
        >
          <HelpOutline
            sx={{ fontSize: "20px", ml: 0.25, color: "primary.main" }}
          />
        </Tooltip>
        <Typography variant="body1" display="inline" ml={1}>
          Enable Smart Pricing
        </Typography>
        <Switch
          name={name}
          value={values.smart_pricing_enabled}
          checked={values.smart_pricing_enabled}
          onChange={() => smartPricingToggle(!values.smart_pricing_enabled)}
          disabled={smartPricingDisabled}
          inputRef={buttonRef}
          sx={{ mr: 1, ml: "auto" }}
        />
      </Box>
      <FormHelperText sx={{ mt: 0.5, mb: 2 }} component="div">
        <List
          sx={{
            listStyleType: "disc",
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
          dense
          component="div"
        >
          {helperTextListItems.map((item, index) => (
            <ListItem sx={{ pl: "10px" }} key={index}>
              {item}
            </ListItem>
          ))}
        </List>
      </FormHelperText>
      {values.smart_pricing_enabled && (
        <AgreementBox agreement={agreement} toggleAgreement={toggleAgreement} />
      )}
    </>
  );
};

export default SmartPricingSwitch;
