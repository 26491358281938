import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import TagSelect from "./TagSelect";
import CategoryIdSelect from "./CategoryIdSelect";
import axios from "axios";

import SiteLogo from "./SIteLogo";
import LogoUploader from "./LogoUploader";
import { LayoutContext, useSnackbar } from "context";
import { SiteContext } from "../components/SiteContext";
import {
  VALIDATOR_STRING_REQUIRED,
  VALIDATOR_URL_REQUIRED,
} from "utils/validators";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";
import FormWrapper from "components/FormWrapper";

const validationSchema = yup.object().shape({
  name: VALIDATOR_STRING_REQUIRED,
  url: VALIDATOR_URL_REQUIRED,
  description: VALIDATOR_STRING_REQUIRED.min(
    120,
    "Newsletter description needs to be at least 120 characters long."
  ),
  audience: VALIDATOR_STRING_REQUIRED.min(
    120,
    "Audience description needs to be at least 120 characters long."
  ),
});

type NewLetterDetailsFormValues = {
  name: string;
  url: string;
  description: string;
  audience: string;
  category_ids: string;
  tag_list: string[];
  logo?: string | File;
  dedicated_ads: boolean;
  email_ads: boolean;
  posts: boolean;
  status: string;
};

export default function NewLetterDetailsForm() {
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { siteSlug } = useParams();
  const { setIsDataLoading } = useContext(LayoutContext);
  const { siteData, setSiteData } = useContext(SiteContext);
  const [initialValues, setInitialValues] =
    useState<NewLetterDetailsFormValues>({
      name: localStorage.getItem("onboardingNewsLetterName") ?? "",
      url: "",
      description: "",
      audience: "",
      category_ids: "",
      tag_list: [],
      logo: "",
      dedicated_ads: false,
      email_ads: false,
      posts: false,
      status: "",
    });

  useComponentDidMount(() => {
    if (siteSlug) {
      setIsDataLoading(true);

      axios({
        method: "get",
        url: `/api/v1/publisher/sites/${siteSlug}`,
        responseType: "json",
      })
        .then((response) => {
          setIsDataLoading(false);
          const {
            name,
            url,
            description,
            audience,
            category_ids,
            tag_list,
            logo,
            dedicated_ads,
            email_ads,
            posts,
            status,
          } = response.data;
          setInitialValues({
            name,
            url,
            description,
            audience: audience ?? "",
            category_ids: category_ids[0] ?? "",
            tag_list,
            logo,
            dedicated_ads: dedicated_ads ?? false,
            email_ads: email_ads ?? false,
            posts: posts ?? false,
            status,
          });
        })
        .catch(noop);
    }
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setErrors }) => {
      if (values === initialValues) {
        return handleNextButton(siteSlug);
      }

      const data = { ...values, tag_list: values.tag_list.join(",") };
      // We only need to send this field if a new file has been uploaded
      if (!(data.logo as File)?.name) {
        delete data.logo;
      }

      setIsDataLoading(true);

      const method = siteSlug ? "patch" : "post";
      const url = siteSlug
        ? `/api/v1/publisher/sites/${siteSlug}`
        : "/api/v1/publisher/sites";

      axios({
        method,
        url,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          localStorage.removeItem("onboardingNewsLetterName");
          setSiteData(response.data);
          const { slug: siteSlug } = response.data;
          setIsDataLoading(false);
          handleNextButton(siteSlug);
        })
        .catch((response) => {
          setIsDataLoading(false);
          const errors = response.response?.data?.errors;
          if (errors) {
            setErrors(errors);
          }
        });
    },
  });

  const handleSwitcherValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleNextButton = (siteSlug: string | undefined) => {
    if (siteData.status === "Live") {
      openSnackbar("Profile updated!");
    } else {
      navigate(`/publisher/sites/${siteSlug}/edit/previous_advertisers`);
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Overview
      </Typography>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Card sx={{ overflow: "visible" }}>
          <CardHeader
            title="Profile Details"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            <Grid container spacing={4} flexDirection="column">
              <Grid item>
                <Typography paddingBottom={1}>
                  Give advertisers more information to get more sponsorships
                  more often. Complete profiles are 2x more likely to get booked
                  on Paved.
                </Typography>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="name">Newsletter Name</InputLabel>
                <TextField
                  fullWidth={true}
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="url">URL</InputLabel>
                <TextField
                  fullWidth={true}
                  id="url"
                  name="url"
                  type="text"
                  value={formik.values.url}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value ?? "";
                    formik.setFieldValue("url", value.toLowerCase().trim());
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                />
              </Grid>
              <Grid item>
                {!formik.values.logo && (
                  <SiteLogo siteUrl={formik.values.url} />
                )}
                <LogoUploader
                  setFieldValue={formik.setFieldValue}
                  currentLogo={formik.values.logo as string}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="description">
                  What's your newsletter about?
                </InputLabel>
                <TextField
                  fullWidth={true}
                  id="description"
                  name="description"
                  type="text"
                  multiline={true}
                  rows={6}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  placeholder="For Example: After launching in July 2013, our site quickly grew into a go-to resource for savvy travelers looking to learn about the latest travel gear and news. Our most popular content includes our video reviews, our travel news email updates, and our travel gear round-ups."
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="audience">
                  Describe your audience to advertisers, please be as
                  descriptive and specific as possible
                </InputLabel>
                <TextField
                  fullWidth={true}
                  id="audience"
                  name="audience"
                  type="text"
                  multiline={true}
                  rows={6}
                  value={formik.values.audience}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.audience && Boolean(formik.errors.audience)
                  }
                  helperText={formik.touched.audience && formik.errors.audience}
                  placeholder="For Example: Our audience is primarily made of avid travelers looking to live a nomadic lifestyle. They are interested in suitcases, backpacks, and other travel gear that can stand up to a rugged trip and still look great. Our most shared articles are in-depth product reviews and travel news updates. We have a lot of bloggers and freelancers in our audience."
                />
              </Grid>
              <Grid item>
                <CategoryIdSelect
                  value={formik.values.category_ids}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item>
                <TagSelect
                  value={formik.values.tag_list}
                  description={formik.values.description}
                  setFieldValue={formik.setFieldValue}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 3 }}>
          <CardHeader
            title="Sponsorship Formats"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <Typography>
                  Select the sponsorship formats you'd like to offer on Paved.
                </Typography>
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    control={
                      <Switch
                        name="posts"
                        checked={formik.values.posts}
                        onChange={handleSwitcherValueChange}
                      />
                    }
                    label={
                      <React.Fragment>
                        Sponsored Blog Post
                        <div>
                          <Typography
                            variant="body2"
                            component="small"
                            color="textSecondary"
                          >
                            A blog post written by you or the advertiser posted
                            on your site. Generally in a how to, review, or
                            editorial format.
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    control={
                      <Switch
                        name="dedicated_ads"
                        checked={formik.values.dedicated_ads}
                        onChange={handleSwitcherValueChange}
                      />
                    }
                    label={
                      <React.Fragment>
                        Dedicated Email
                        <div>
                          <Typography
                            variant="body2"
                            component="small"
                            color="textSecondary"
                          >
                            An email that only contains advertorial content.
                            Also called an e-blast.
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="email_ads"
                        checked={formik.values.email_ads}
                        onChange={handleSwitcherValueChange}
                      />
                    }
                    label={
                      <React.Fragment>
                        Sponsored Email
                        <div>
                          <Typography
                            variant="body2"
                            component="small"
                            color="textSecondary"
                          >
                            Native ads that appear alongside your regular
                            newsletter content.
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button color="primary" variant="contained" type="submit">
            {siteData.status === "Live" ? "Save Changes" : "Next"}
          </Button>
        </Box>
      </FormWrapper>
    </>
  );
}
