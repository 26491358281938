import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import Icon from "../Icon";
import { Dialog } from "../MUIWrappers";
import { Action, Actions } from "./ConfirmationDialog";
import { FONTS_ICONS_LIST } from "../Icon/Icon";

import theme from "../theme";
const { palette } = theme;
export const CONFIRMATION_DIALOG_MESSAGE_VARIANTS: {
  [key: string]: { icon: keyof typeof FONTS_ICONS_LIST; color: string };
} = {
  success: { icon: "checkCircle", color: palette.success.main },
  warning: { icon: "question", color: palette.error.main },
  star: { icon: "star", color: palette.warning.main },
  dissatisfied: { icon: "sad", color: palette.primary.light },
  error: { icon: "warning", color: palette.error.main },
};

interface MessageContentProps {
  variant: keyof typeof CONFIRMATION_DIALOG_MESSAGE_VARIANTS;
  messageTitle: string;
  messageDescription: string;
  actions?: Action[];
}

export interface ConfirmationMessageDialogProps extends MessageContentProps {
  open: boolean;
  onClose: () => void;
}

const MessageContent: FC<MessageContentProps> = ({
  variant,
  messageTitle,
  messageDescription,
  actions = [],
}) => {
  return (
    <Stack direction="column" alignItems="center" gap={5} textAlign="center">
      <Icon
        name={CONFIRMATION_DIALOG_MESSAGE_VARIANTS[variant].icon}
        fill={CONFIRMATION_DIALOG_MESSAGE_VARIANTS[variant].color}
        weight={300}
        width={124}
        height={124}
      />
      <Stack
        direction="column"
        gap={2}
        minHeight={actions.length > 0 ? "144px" : "auto"}
      >
        <Typography fontWeight={700} fontSize="24px">
          {messageTitle}
        </Typography>
        <Typography fontWeight={400} fontSize="14px" color="text.secondary">
          {messageDescription}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ConfirmationMessageDialog: FC<ConfirmationMessageDialogProps> = ({
  open,
  onClose,
  actions = [],
  variant,
  messageTitle,
  messageDescription,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "420px",
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-end" mt={1} mr={1}>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Stack
        px={{ xs: 2, sm: 4 }}
        pb={{ xs: 2, sm: 4 }}
        direction="column"
        gap={5}
      >
        <MessageContent
          actions={actions}
          variant={variant}
          messageTitle={messageTitle}
          messageDescription={messageDescription}
        />
        {actions.length > 0 && (
          <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 1, sm: 2 }}>
            <Actions actions={actions} />
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};
