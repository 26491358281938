import { useCallback } from "react";
import {
  getCampaigns,
  markAsRead as apiMarkAsRead,
  markAsUnread as apiMarkAsUnread,
} from "../api/chatsAPI";
import { ChatAction, ChatActions } from "../types/chatActions";
import { Filters, GetCampaignsResponse } from "../types/types";
import { UserType } from "types";

export const useCampaigns = (
  dispatch: React.Dispatch<ChatAction>,
  trackEvent: (event: string, properties?: Record<string, unknown>) => void,
  userType: UserType | undefined,
  filters: Filters
) => {
  const fetchCampaigns = useCallback(
    async (showLoading = false) => {
      if (showLoading) {
        dispatch({ type: ChatActions.FETCH_CAMPAIGNS_REQUEST });
      }
      try {
        const response: GetCampaignsResponse = await getCampaigns(filters);
        dispatch({
          type: ChatActions.FETCH_CAMPAIGNS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        console.error("Failed to fetch campaigns:", error);
        dispatch({ type: ChatActions.FETCH_CAMPAIGNS_FAILURE });
      }
    },
    [dispatch, filters]
  );

  const markAsRead = useCallback(
    async (id: string) => {
      dispatch({ type: ChatActions.MARK_CAMPAIGN_AS_READ, payload: id });
      trackEvent(`${userType}_marked_campaign_as_read`, { campaignId: id });

      try {
        await apiMarkAsRead(id);
      } catch (error) {
        console.error("Failed to mark as read:", error);
        dispatch({ type: ChatActions.MARK_CAMPAIGN_AS_UNREAD, payload: id });
      }
    },
    [dispatch, trackEvent, userType]
  );

  const markAsUnread = useCallback(
    async (id: string) => {
      try {
        await apiMarkAsUnread(id);
        dispatch({ type: ChatActions.MARK_CAMPAIGN_AS_UNREAD, payload: id });
        trackEvent(`${userType}_marked_campaign_as_unread`, { campaignId: id });
      } catch (error) {
        console.error("Failed to mark as unread:", error);
      }
    },
    [dispatch, trackEvent, userType]
  );

  return { fetchCampaigns, markAsRead, markAsUnread };
};
