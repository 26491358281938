import React, { useState, useEffect } from "react";
import { Box, Button, FormHelperText, InputLabel } from "@mui/material";
import axios from "axios";
import { PixelCrop } from "react-image-crop";
import PreviewSampleEmail from "../components/PreviewSampleEmail";

interface Props {
  exampleUrl: string;
  hightlightPlacement: string;
  siteSlug: string;
  id: string;
}

export interface UploadPlacement {
  placement_img_data: string;
  placement_coordinates: PixelCrop | undefined;
}

export default function PlacementExample({
  exampleUrl,
  hightlightPlacement,
  siteSlug,
  id,
}: Props) {
  const [openSamplePreview, setOpenSamplePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(hightlightPlacement);
  const [loading, setLoading] = useState(false);

  useEffect(() => setPreviewImage(hightlightPlacement), [hightlightPlacement]);

  const handleUploadPlacement = (requestBody: UploadPlacement) => {
    setLoading(true);
    return axios
      .patch(
        `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/placement_upload`,
        { id, ...requestBody }
      )
      .then((response) => {
        setPreviewImage(response.data.placement_screenshot);
      })
      .finally(() => {
        setLoading(false);
        setOpenSamplePreview(false);
      });
  };

  return (
    <Box px={3}>
      <InputLabel>Placement Example</InputLabel>
      {previewImage ? (
        <>
          <Box
            paddingBottom={1.5}
            sx={{
              "& img": {
                maxWidth: "400px",
                maxHeight: "400px",
                border: "1px black solid",
              },
            }}
          >
            <img src={previewImage} alt="" />
          </Box>
          <Box display="flex" gap={0.5}>
            <Button
              variant="outlined"
              disabled={loading}
              sx={{
                color: "text.primary",
                borderColor: "rgba(0,40,100,.12)",
              }}
              size="small"
              onClick={() => setOpenSamplePreview(true)}
            >
              Change
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={loading}
              sx={{
                color: "text.primary",
                borderColor: "rgba(0,40,100,.12)",
              }}
              onClick={() =>
                handleUploadPlacement({
                  placement_img_data: "",
                  placement_coordinates: undefined,
                })
              }
            >
              Remove
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            sx={{
              color: "text.primary",
              borderColor: "rgba(0,40,100,.12)",
            }}
            onClick={() => setOpenSamplePreview(true)}
          >
            Select placement
          </Button>
          <FormHelperText>
            Hightlight the placement within your email.
          </FormHelperText>
        </>
      )}

      <PreviewSampleEmail
        open={openSamplePreview}
        exampleUrl={exampleUrl}
        isPlacementExample
        isLoading={loading}
        handleClose={() => setOpenSamplePreview(false)}
        handleUploadPlacement={(requestBody: UploadPlacement) =>
          handleUploadPlacement(requestBody)
        }
      />
    </Box>
  );
}
