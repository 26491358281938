import { DescriptionOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { Message } from "../types/types";
import { Link } from "react-router-dom";

interface MessageItemProps {
  message: Message;
  isCompanyMessage: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  isCompanyMessage,
}) => {
  const [isImageError, setIsImageError] = useState(false);

  const messageText = message.message && (
    <Typography variant="body2" mb={message.attachment ? 1 : 0}>
      {message.message}
    </Typography>
  );

  const attachmentThumbnail =
    !isImageError && message.attachment?.thumbnail ? (
      <CardMedia
        src={message.attachment?.thumbnail}
        onError={() => setIsImageError(true)}
        component="img"
        height={90}
        sx={{ borderRadius: "4px", maxWidth: 90 }}
      />
    ) : (
      <Box
        bgcolor="common.white"
        p={1}
        borderRadius="4px"
        height={40}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <DescriptionOutlined />
      </Box>
    );

  const attachmentInfo = message.attachment?.url && (
    <Box display="flex" alignItems="center" gap={1}>
      {attachmentThumbnail}
      <Typography
        variant="body2"
        fontWeight="bold"
        sx={{
          wordBreak: "break-all",
          mb: "auto",
          flexGrow: 1,
        }}
      >
        {message.attachment.filename}
      </Typography>
    </Box>
  );

  const attachmentActions = message.attachment?.url && (
    <Link
      target="_blank"
      to={message.attachment.url ? message.attachment.url : ""}
    >
      <IconButton
        color="primary"
        size="small"
        aria-label="Open attachment in new tab"
        sx={{ padding: 0, marginBottom: 0.5 }}
      >
        <FileDownloadOutlined />
      </IconButton>
    </Link>
  );

  const timestamp = (
    <Typography
      variant="caption"
      color="text.secondary"
      textAlign="end"
      mt="auto"
      width="max-content"
    >
      {format(new Date(message.created_at * 1000), "HH:mm")}
    </Typography>
  );

  const messageContent = (
    <Box flexShrink={1} display="flex" flexDirection="column">
      {messageText}
      {attachmentInfo}
    </Box>
  );

  const actionsAndTimestamp = (
    <Box display="flex" flexDirection="column" alignItems="flex-end" ml={1}>
      {attachmentActions}
      {timestamp}
    </Box>
  );

  return (
    <Box
      mb={2}
      display="flex"
      justifyContent={isCompanyMessage ? "flex-end" : "flex-start"}
    >
      <Box
        display="flex"
        sx={{
          backgroundColor: isCompanyMessage ? "#E3F2FD" : "#F5F6FA",
          p: 1.5,
          borderRadius: "12px",
          maxWidth: { xs: "90%", lg: "75%" },
          wordBreak: "break-word",
        }}
      >
        {messageContent}
        {actionsAndTimestamp}
      </Box>
    </Box>
  );
};

export default React.memo(MessageItem);
