import {
  GetCampaignsResponse,
  Messages,
  GroupedMessages,
  Message,
  Filters,
} from "./types";

export enum ChatActions {
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  SET_FILTERS,
  MARK_CAMPAIGN_AS_READ,
  MARK_CAMPAIGN_AS_UNREAD,
  SEND_MESSAGE,
  SET_GROUPED_MESSAGES,
}

export type ChatAction =
  | { type: ChatActions.FETCH_CAMPAIGNS_REQUEST }
  | { type: ChatActions.FETCH_CAMPAIGNS_SUCCESS; payload: GetCampaignsResponse }
  | { type: ChatActions.FETCH_CAMPAIGNS_FAILURE }
  | {
      type: ChatActions.FETCH_MESSAGES_REQUEST;
      payload: { isPolling: boolean };
    }
  | {
      type: ChatActions.FETCH_MESSAGES_SUCCESS;
      payload: { messages: Messages; isPolling: boolean };
    }
  | {
      type: ChatActions.FETCH_MESSAGES_FAILURE;
      payload: { isPolling: boolean };
    }
  | {
      type: ChatActions.SET_FILTERS;
      payload: Filters;
    }
  | { type: ChatActions.MARK_CAMPAIGN_AS_READ; payload: string }
  | { type: ChatActions.MARK_CAMPAIGN_AS_UNREAD; payload: string }
  | { type: ChatActions.SEND_MESSAGE; payload: Message }
  | { type: ChatActions.SET_GROUPED_MESSAGES; payload: GroupedMessages[] };
