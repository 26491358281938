import { Close, DeleteOutlined } from "@mui/icons-material";
import { Box, Chip, Skeleton, useTheme } from "@mui/material";
import { grey, indigo } from "@mui/material/colors";
import { UserContext } from "context";
import { UserType } from "pages/users/sign-up/types";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useChat } from "../context/ChatContext";
import { ChatActions } from "../types/chatActions";

interface FilterOption {
  label: string;
  type: "sponsorship_type" | "status";
  key: string;
}

const QuickFilters: React.FC = () => {
  const {
    dispatch,
    state: { filters },
  } = useChat();
  const { userData } = useContext(UserContext);
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [dragging, setDragging] = useState(false);

  const dragThreshold = 5;
  const hasMoved = useRef(false);

  const setGrabbing = () => {
    if (containerRef.current) {
      containerRef.current.style.cursor = "grabbing";
      containerRef.current.style.userSelect = "none";
    }
  };

  const setGrab = () => {
    if (containerRef.current) {
      containerRef.current.style.cursor = "grab";
      containerRef.current.style.removeProperty("user-select");
    }
  };

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
      setGrabbing();
      hasMoved.current = false;
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging || !containerRef.current) return;
      const x = e.pageX - containerRef.current.offsetLeft;
      const walk = x - startX;
      if (Math.abs(walk) > dragThreshold) {
        hasMoved.current = true;
      }
      containerRef.current.scrollLeft = scrollLeft - walk;
    },
    [isDragging, startX, scrollLeft]
  );

  const endDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      setDragging(hasMoved.current);
      setGrab();
    }
  }, [isDragging]);

  const handleMouseLeave = useCallback(endDragging, [endDragging]);
  const handleMouseUp = useCallback(endDragging, [endDragging]);

  const handleMouseEnter = useCallback(() => {
    if (!isDragging && containerRef.current) {
      containerRef.current.style.cursor = "grab";
    }
  }, [isDragging]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (dragging) {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
      }
    },
    [dragging]
  );

  const handleTabClick = useCallback(
    (newActionStatus?: string, newStatus?: string[]) =>
      (e: React.MouseEvent) => {
        if (dragging) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        dispatch({
          type: ChatActions.SET_FILTERS,
          payload: {
            action_status: newActionStatus,
            status: newStatus,
            per_page: 50,
          },
        });
      },
    [dragging, dispatch]
  );

  const handleClearAll = useCallback(
    (e: React.MouseEvent) => {
      if (dragging) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      dispatch({
        type: ChatActions.SET_FILTERS,
        payload: {
          sponsorship_type: [],
          status: [],
          action_status: undefined,
          query: "",
          per_page: 50,
        },
      });
    },
    [dragging, dispatch]
  );

  const tabs = useMemo(
    () => [
      {
        label: "All",
        selected:
          !filters.action_status &&
          !(filters.status && filters.status.length > 0),
        onClick: handleTabClick(undefined, undefined),
      },
      {
        label: "Action Required",
        selected: filters.action_status === "action_required",
        onClick: handleTabClick("action_required", undefined),
      },
      {
        label: `Awaiting ${
          userData?.userType === UserType.ADVERTISER
            ? "Publisher"
            : "Advertiser"
        }`,
        selected: filters.action_status === "action_pending",
        onClick: handleTabClick("action_pending", undefined),
      },
      {
        label: "Completed",
        selected: filters.status?.includes("Sent") ?? false,
        onClick: handleTabClick(undefined, ["Sent"]),
      },
    ],
    [filters, handleTabClick, userData?.userType]
  );

  const formatFilterOptions = useCallback(
    (
      filterArray?: string[],
      type?: "sponsorship_type" | "status"
    ): FilterOption[] =>
      filterArray?.map((filter) => ({
        label: filter === "Sent" ? "Completed" : filter.replace(/_/g, " "),
        type: type!,
        key: filter,
      })) || [],
    []
  );

  const filterOptions = useMemo(() => {
    const sponsorshipTypes = formatFilterOptions(
      filters.sponsorship_type,
      "sponsorship_type"
    );
    const statuses = formatFilterOptions(filters.status, "status");
    return [...sponsorshipTypes, ...statuses];
  }, [filters, formatFilterOptions]);

  const handleRemoveFilter = useCallback(
    (type: "sponsorship_type" | "status", key: string) =>
      (e: React.MouseEvent) => {
        if (dragging) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        const updatedFilter = Array.isArray(filters[type])
          ? filters[type]?.filter((item: string) => item !== key)
          : [];
        dispatch({
          type: ChatActions.SET_FILTERS,
          payload: {
            [type]: updatedFilter,
            per_page: 50,
          },
        });
      },
    [dragging, dispatch, filters]
  );

  const chipStyles = {
    fontWeight: 500,
    height: "32px",
    color: grey[600],
    bgcolor: indigo[50],
    "&:hover": {
      bgcolor: indigo[100],
    },
  };

  const selectedChipStyles = {
    ...chipStyles,
    border: `1px solid ${theme.palette.primary.main}`,
    color: "primary.main",
  };

  return (
    <Box
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      sx={{
        display: "flex",
        gap: 1,
        px: 1,
        py: "12px",
        overflowX: "auto",
        cursor: isDragging ? "grabbing" : "grab",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        userSelect: isDragging ? "none" : "auto",
        transition: "cursor 0.2s",
      }}
    >
      {filterOptions.length > 0 ? (
        <>
          <Chip
            key="clear-all"
            label={<DeleteOutlined fontSize="small" sx={{ mb: "-5px" }} />}
            clickable
            onClick={handleClearAll}
            sx={{
              ...chipStyles,
            }}
            aria-label="Clear all filters"
          />
          {filterOptions.map(({ label, type, key }) => (
            <Chip
              key={`${type}-${key}`}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                    fontSize: "inherit",
                    fontWeight: 500,
                  }}
                >
                  {label}
                  <Close fontSize="inherit" sx={{ ml: 0.5 }} />
                </span>
              }
              sx={chipStyles}
              onClick={handleRemoveFilter(type, key)}
              aria-label={`Remove filter ${label}`}
            />
          ))}
        </>
      ) : (
        tabs.map(({ label, selected, onClick }) =>
          userData?.userType ? (
            <Chip
              key={label}
              label={label}
              clickable
              onClick={onClick}
              aria-label={`Filter by ${label}`}
              sx={selected ? selectedChipStyles : chipStyles}
            />
          ) : (
            <Skeleton
              key={label}
              variant="rounded"
              width={100}
              height={26}
              sx={{ borderRadius: 50 }}
            />
          )
        )
      )}
    </Box>
  );
};

export default QuickFilters;
