import axios from "axios";
import { GetCampaignsResponse, Message, Messages } from "../types/types";

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const API_BASE = "/api/v1/chats";

export async function getCampaigns(filters?: {
  action_status?: string;
  status?: string[];
  sponsorship_type?: string[];
  query?: string;
  page?: number;
  count_only?: boolean;
  per_page?: number;
}): Promise<GetCampaignsResponse> {
  const params = new URLSearchParams();

  //
  if (filters?.action_status) {
    params.append("filters[action_status]", filters.action_status);
  }

  if (filters?.status && filters.status.length > 0) {
    filters.status.forEach((s) => params.append("filters[status][]", s));
  }

  if (filters?.sponsorship_type && filters.sponsorship_type.length > 0) {
    filters.sponsorship_type.forEach((type) =>
      params.append("filters[sponsorship_type][]", type)
    );
  }

  if (filters?.query) {
    params.append("query", filters.query);
  }

  if (filters?.per_page) {
    params.append("per_page", filters.per_page.toString());
  }

  if (filters?.count_only) {
    params.append("count_only", "true");
  }
  const url = params.toString() ? `${API_BASE}?${params.toString()}` : API_BASE;
  const response = await axios.get<GetCampaignsResponse>(url);

  if (!response.data) {
    throw new Error("Failed to fetch campaigns");
  }
  return response.data;
}

export const getMessages = async (campaignUuid: string): Promise<Messages> => {
  const response = await axios.get<Messages>(`${API_BASE}/${campaignUuid}`);
  return response.data;
};

export const sendMessage = async (
  campaignUuid: string,
  message: string,
  attachment?: File
): Promise<Message> => {
  if (!campaignUuid) {
    throw new Error("Campaign UUID is required");
  }
  if (attachment) {
    if (attachment.size > MAX_FILE_SIZE) {
      throw new Error("File size exceeds 10MB limit");
    }
  }
  const formData = new FormData();
  formData.append("message", message);
  if (attachment) {
    formData.append("attachment", attachment, attachment.name);
  }
  const controller = new AbortController();
  try {
    const response = await axios.post<Message>(
      `${API_BASE}/${campaignUuid}/create`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: controller.signal,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to send message`);
    }
    throw error;
  }
};

export const markAsRead = async (campaignUuid: string): Promise<void> => {
  await axios.patch(`${API_BASE}/${campaignUuid}/mark_as_read`);
};

export const markAsUnread = async (campaignUuid: string): Promise<void> => {
  await axios.patch(`${API_BASE}/${campaignUuid}/mark_as_unread`);
};
